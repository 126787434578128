import { registerRoute } from 'workbox-routing';
import { cacheFirstHandler } from './config';

const setup = () => {
  // Fetch and cache images using CORS that are from the Google CDN
  registerRoute(new RegExp('.*.googleapis.com.*'), cacheFirstHandler({ cors: true }));
  // Fetch and cache images using CORS that are from the Contentful CDN
  registerRoute(new RegExp('.*.ctfassets.net.*'), cacheFirstHandler({ cors: true }));
  // Fetch and cache images not using CORS that are from the Americana CDN
  registerRoute(new RegExp('.*.americana.com.*'), cacheFirstHandler({ cors: false }));
};

export { setup };
