import { CacheFirst } from 'workbox-strategies';
import { ExpirationPlugin } from 'workbox-expiration';
import { CacheableResponsePlugin } from 'workbox-cacheable-response';
import { fetchDidSucceedPlugin } from './fetchDidSuceedPlugin';

/**
 * @param {Object} options - Options
 * @param {Boolean} options.cors - Whether or not to use CORS
 * @returns {CacheFirst} CacheFirst strategy implementation
 * @description
 * This function returns a CacheFirst handler that can be used to cache responses
 * from a specific CDN or Pattern. The handler will cache responses for 30 days and will
 * cache up to 4000 entries.
 *
 * The handler will use CORS if the `options.cors` is set to true. If the
 * `options.cors` is set to false, the handler will not use CORS for pre-fetching requests.
 *
 * The handler will cache responses that have a status code of 200 or 0. If the
 * `options.cors` is set to true, the handler will only cache responses that have
 * a status code of 200.
 *
 * If the response returns a 404 status, we will need to create a record in the new relic
 * so that the customer can correct the problem.
 *
 * !!!!!!!
 * Today we use the no-cors option to fetch Americana images, so we can't register missing images
 * the response is opaque, regardless of whether the status is 200 or 404, so we don't know when the image is missing
 * !!!!!!!
 */

export const cacheFirstHandler = ({
  cors = false,
  age = 1 * 24 * 60 * 60, // By default, cache for 1 day as we update menus hourly
}) => {
  return new CacheFirst({
    cacheName: 'images',
    fetchOptions: {
      credentials: 'omit',
      mode: cors ? 'cors' : 'no-cors',
    },
    plugins: [
      new CacheableResponsePlugin({
        statuses: cors ? [200] : [0, 200],
      }),
      new ExpirationPlugin({
        maxEntries: 4000, // Number of images to cache
        maxAgeSeconds: age,
      }),
      {
        fetchDidSucceed: fetchDidSucceedPlugin,
      },
    ],
  });
};
