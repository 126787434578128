const extractProductIdFromUrl = url => {
  const lastSlashIndex = url.lastIndexOf('%2F');

  if (lastSlashIndex === -1) {
    return '';
  }

  try {
    const extractedValue = url.substring(lastSlashIndex + 3);
    return extractedValue.split('.')[0];
  } catch (err) {
    return '';
  }
};

export { extractProductIdFromUrl };
