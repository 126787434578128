import { extractProductIdFromUrl } from './extractProductIdFromUrl';

export const fetchDidSucceedPlugin = async ({ response }) => {
  if (response.status === 404) {
    try {
      const { url } = response;

      const productId = extractProductIdFromUrl(url);

      /* eslint-disable no-restricted-globals */
      /* eslint-disable no-undef */
      self.clients.matchAll({ type: 'window' }).then(client => {
        client[0].postMessage({ productId });
      });
    } catch (error) {
      return response;
    }
  }

  return response;
};
