/* eslint-disable no-undef */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-restricted-globals */
import { setup as setupImages } from './images';

self.__WB_DISABLE_DEV_LOGS = true; // disable workbox logs, might enable for certain envs

self.addEventListener('install', () => {
  // tells service worker to skip installing and activate it
  self.skipWaiting();
});

self.addEventListener('activate', () => {
  clients.claim();
});

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.addEventListener('message', event => {
    if (event.data.productId) {
      window.logger.info(`Menu - missing image for product ${event.data.productId}`, {
        sendSessionId: false,
        loggerOptions: true,
      });
    }
  });
}

setupImages();
